<template>
  <main id="content" role="main">
    <div class="d-lg-flex">
      <div
        class="container d-md-flex align-items-md-center min-vh-md-100 space-bottom-2 space-top-lg-3 space-top-2 space-bottom-lg-3 space-lg-0 pt-lg-8"
      >
        <div class="col-md-7 mb-4 mb-lg-0">
          <div class="header-section mb-5">
            <div>
              <nav class="navbar navbar-left" @click="$router.push({name: 'Home'})">
                <a class="navbar-brand">
                  <img src="@/assets/logo.png" alt="Logo" />
                </a>
              </nav>
            </div>
          </div>
          <div class="mb-6">
            <h1 class="h2">Thriftax Webinars</h1>
            <p>
              Thriftax tax experts are offering free webinars to nonresidents
              across the US. Topics include what forms you need file, when to
              file, and how to file tax returns as a nonresident.
            </p>
            <p>
              The link below will redirect you to the soonest webinar we're
              offering.
            </p>
            <p>
              Next webinar: <br />
              {{ currentSlot.datetime }} PST
            </p>
          </div>
          <a
            class="btn btn-secondary btn-wide transition-3d-hover"
            :class="btnActive ? '' : 'disabled'"
            :href="currentSlot.url"
            >Join Webinar</a
          >
        </div>
        <div class="col h4">
          <div class="card p-4">
            Webinar Time Slots (PST)
            <ul class="pl-5">
              <li
                class="mt-2"
                v-for="slot in slots"
                :key="slot.datetime"
              >
                {{ slot.datetime }}
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import { unauthClient } from "@/api";

const monthNames = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December"
];

export default {
  data() {
    return {
      url: "",
      slots: [],
      currentSlot: {},
      btnActive: false,
    };
  },
  created() {
    unauthClient.get("/webinars/").then(response => {
      this.slots = response.data.instances;
      for (var slot of this.slots) {
        slot.start_time = new Date(slot.start_time);
        var hour = slot.start_time.getHours();
        if (hour > 12) {
          hour -= 12;
          var period = "PM";
        } else {
          var period = "AM";
        }
        var minute = slot.start_time.getMinutes();
        if (minute < 10) {
          minute = '0' + String(minute);
        }
        var month = monthNames[slot.start_time.getMonth()];
        var day = slot.start_time.getDate();
        slot.datetime = `${month} ${day}, ${hour}:${minute} ${period}`;
      }
      this.btnActive = response.data.active;
      console.log(response.data.active);
      this.currentSlot = this.slots[response.data.current_index];
    });
  }
};
</script>
